import { generalFieldValidation } from "components/CMS/shared/FieldValidations";
import {
  applicationIdRegex,
  nameRegex,
  phoneRegex,
  sofrRegex,
} from "lib/utils/regexExpressions";

export const isValidRoutingNumber = (value) => value?.length === 9;

export const routingNumberValidation = (value) => {
  generalFieldValidation(value);
  if (!isValidRoutingNumber(value)) {
    return "Invalid number.";
  }
};

export const isValidLeadSearchInput = (value) =>
  applicationIdRegex.test(value) ||
  nameRegex.test(value) ||
  phoneRegex.test(value);

export const isValidSofr = (value) => sofrRegex.test(value);
