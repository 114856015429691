import { extractDate } from "lib/utils/dateUtils";

export const mutateFetchedSofrValues = (data) => {
  if (
    data.applicationSofrDetails &&
    Array.isArray(data.applicationSofrDetails)
  ) {
    data.applicationSofrDetails.forEach((detail) => {
      if (detail.capturedAt) {
        detail.capturedAt = extractDate(detail.capturedAt);
      }
    });
  }
  return data;
};
