import axios from "axios";

export const requestSofrDetails = (slug) => {
  const params = slug ? `?slug=${slug}` : "";
  return axios.get(`/api/route?path=in-school/sofr-details/fetch${params}`);
};

export const requestCaptureSofrDetails = (data) => {
  return axios.post(
    `/api/route?path=in-school/sofr-details/global/capture`,
    data
  );
};
