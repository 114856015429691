import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  sofrDetails: null,
};

export const fetchSofrDetails = createAction(
  "manualActivityDetails/fetchSofrDetails"
);

export const captureSofrDetails = createAction(
  "manualActivityDetails/captureSofrDetails"
);

const ManualActivitySlice = createSlice({
  name: "manualActivityDetails",
  initialState,
  reducers: {
    updateIsLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    updateSofrDetails(state, action) {
      return { ...state, sofrDetails: action.payload };
    },
  },
});

export const { updateIsLoading, updateSofrDetails } =
  ManualActivitySlice.actions;
export default ManualActivitySlice.reducer;
