import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  captureSofrDetails,
  fetchSofrDetails,
  updateIsLoading,
  updateSofrDetails,
} from "components/ManualActivity/redux/ManualActivitySlice";
import {
  requestCaptureSofrDetails,
  requestSofrDetails,
} from "components/ManualActivity/redux/ManualActivityApi";
import { mutateFetchedSofrValues } from "components/ManualActivity/redux/ManualActivityHelper";

function* handleFetchSofrDetails(action) {
  try {
    const res = yield call(requestSofrDetails, action.payload);
    if (res?.data?.success) {
      yield put(updateSofrDetails(mutateFetchedSofrValues(res?.data?.data)));
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleCaptureSofrDetails(action) {
  try {
    yield put(updateIsLoading(true));
    const res = yield call(requestCaptureSofrDetails, action.payload);
    if (res?.data?.success) {
      toast.success("Updated Successfully");
      yield put(fetchSofrDetails());
    } else {
      toast.error(res?.data?.message || errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.log(err);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsLoading(false));
  }
}

function* ManualActivitySaga() {
  yield takeLatest(fetchSofrDetails, handleFetchSofrDetails);
  yield takeLatest(captureSofrDetails, handleCaptureSofrDetails);
}

export default ManualActivitySaga;
