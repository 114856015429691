import { combineReducers } from "@reduxjs/toolkit";
import CMSReducer from "../components/CMS/CMSReducer";
import BankAccountUpdateSlice from "components/DisbursementApplicationLeadDetails/BankAccountUpdate/redux/BankAccountUpdateSlice";
import DisbursementActivityStateSlice from "components/DisbursementApplicationLeadDetails/DisbursementActivityState/redux/DisbursementActivityStateSlice";
import ManualActivitySlice from "components/ManualActivity/redux/ManualActivitySlice";

export default combineReducers({
  cms: CMSReducer,
  bankAccountUpdate: BankAccountUpdateSlice,
  disbursementActivityState: DisbursementActivityStateSlice,
  manualActivityDetails: ManualActivitySlice,
});
